<template>
  <div class="order_records">
    <div class="records_tab">
      <div
        v-for="(ele, idx) in tabList"
        :key="idx + Math.random()"
        :class="{ active: type === ele.val }"
        @click="changeType(ele.val)"
      >
        {{ ele.name }}
        <span class="bottom-bar"></span>
      </div>
    </div>
    <div class="records_table">
      <van-empty
        v-if="list.length===0"
        :image="require('@/assets/empty.png')"
        image-size="80"
        description="暂无预约记录"
      />
      <template v-else>
        <div class="table_item" v-for="(ele,idx) in list" :key="idx">
          <div class="item_row">
            <span>预约事项</span>
            <span>{{ele.matterName}}</span>
          </div>
          <div class="item_row">
            <span>预约日期</span>
            <span>{{ele.appointmentDate}}</span>
          </div>
          <div class="item_row">
            <span>预约时段</span>
            <span>{{ele.startTime}}-{{ele.endTime}}</span>
          </div>
          <div class="item_row_btn">
            <div class="btn plain" v-if="ele.ticketStatus===0" @click="onCancle(ele)">取消预约</div>
            <div class="btn" @click="goTo(ele)">详情</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getList,getDictionary,update } from "@/api/order";
import { mapState } from "vuex";
import {Empty, Toast,Dialog} from 'vant';
export default {
  name: "orderRecords",
  components: {
    [Empty.name]:Empty
  },
  data() {
    return {
      tabList: [],
      type: '',
      loadEnd: false,
      list: [],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  mounted() {
    getDictionary('ticket_status').then(res=>{
      if(res.success){
        this.tabList = res.data.map(item=>{
          return {
            name:item.dictValue,
            val:item.dictKey
          }
        });
        this.type=this.tabList[0]?.val
        this.init();
      }
    })
  },
  methods: {
    changeType(val){
      if(this.type!==val){
        this.type = val;
        this.list = [];
        this.init();
      }
    },
    init() {
      getList({
        phone: this.userInfo.phone,
        ticketStatus:this.type,
        size: 1000,
        page: 1,
      }).then((res) => {
        if (res.success) {
          this.list = res.data.records;
        }
      });
    },
    onCancle(row){
      Dialog.confirm({
        title: '确认取消预约',
      })
        .then(() => {
          const params = {
            ...row,
            ticketStatus:2
          }
          update(params).then(res=>{
            if(res.success){
              this.init();
              Toast('操作成功')
            }
          })
        })
        .catch(() => {
        });
    },
    goTo(row){
      this.$router.push({path:'/orderCode',query:{detailObj:encodeURIComponent(JSON.stringify(row))}});
    }
  },
};
</script>

<style scoped lang="scss">
.order_records {
  height: 100vh;
  padding-bottom: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .records_tab {
    height: 32px;
    margin-bottom: 12px;
    display: flex;
    background: #ffffff;
    line-height: 32px;
    text-align: center;
    color: #666666;
    font-size: 16px;

    > div {
      width: 50%;
      position: relative;

      &.active {
        color: #277cf3;

        .bottom-bar {
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          width: 14px;
          height: 3px;
          background: #277cf3;
          opacity: 1;
          border-radius: 1px;
        }
      }

      > span {
        display: none;
      }
    }
  }
  .records_table{
    padding:0 12px;
    overflow: auto;
    flex: 1;
    .table_item{
      padding: 16px 12px;
      border-radius: 5px;
      background-color: #fff;
      +.table_item{
        margin-top: 12px;
      }
      .item_row{
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        +.item_row{
          margin-top: 12px;
        }
        span:nth-of-type(1){
          opacity: 0.8;
        }
      }
      .item_row_btn{
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;
        .btn{
          width: 56px;
          height: 24px;
          border-radius: 5px;
          background-color: #1677FF;
          color: #fff;
          font-size: 12px;
          text-align: center;
          line-height: 24px;
          &.plain{
            width: 72px;
            margin-right: 12px;
            color: #1677FF;
            border: 1px solid #1677FF;
            background-color: #fff;
          }
        }
      }
    }
  }
}
</style>